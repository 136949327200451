<template>
    <div class="seachBody">
        <el-transfer filterable  v-model="choicedData" :titles="['选择供应商', '已选择供应商']" :button-texts="['到左边', '到右边']" :data="choiceData"/>
    </div>
</template>

<script>
import Component from '@/assets/js/components.js'
export default {
  components: Component.components,
  props: ['choiceData', 'choiced'],
  name: 'ChoiceSupplierBody',
  data: function () {
    return {
      choicedData: this.choiced
    }
  },
  methods: {
  },
  watch: {
    choiced: function (val) {
      this.choicedData = val
    }
  }
}
</script>

<style scoped lang="scss">
.seachBody{
  :deep(.el-transfer-panel){
        height: calc(100vh - 300px);
    }
  :deep(.el-transfer-panel__list.is-filterable){
        height: calc(100vh - 400px);
    }
  :deep(.el-transfer){
    .el-transfer-panel{
      width: 350px;
      .el-transfer-panel__body{
        .el-input{
          width: calc(100% - 30px);
        }
      }
    }
    .el-button+.el-button{
      display: block;
      margin-left: 0px;
    }
    .el-button [class*=el-icon-]+span {
      margin-left: 0px;
    }
  }
  .tableNav {
    border-bottom: 10px solid #FBFCFD;
    padding-top: 0;
    :deep(.searchButton){
      margin-right: 2px;
    }
    :deep(.openClose){
      display: none;
    }
    :deep(.searchList){
      padding-right: 0px;
      .col2:first-child{
        .title{
          width: 25%;
          min-width: auto;
        }
      }
    }
  }
  padding-bottom: 10px;
}
</style>
